<template>
	<div>
    Signing out...
  </div>
</template>	
<script type="text/javascript"></script>
<script>

  import auth from '@/cognito.js';

  //import Signinapi from '@/services/api/signin'

  export default{
    
    data(){
      return{

      }
    },
    computed: {},
    filters: {},
    mounted() { 
          

          this.$cookie.set('usertoken', '');
          this.$cookie.set('organizationkey', '');
          this.$cookie.set('signinmethod', "");

          localStorage.token = ""
          
          auth.logout();

          //localStorage.setItem('message',JSON.stringify( {'command':'signoutall'} ));
          let self = this
          //localStorage.removeItem('message');
          //this.$store.dispatch('doSignOut')
          //.then(() =>{
           setTimeout(function() {
            location.href='/'
            //self.$router.push()
          },3000)
         //})

        },


          methods: {
          }
        }
      </script>